import React from "react";
import InvoiceInner from "../components/InvoiceInner";

const Invoice = () => {
  return (
    <>
      <InvoiceInner />
    </>
  );
};

export default Invoice;
